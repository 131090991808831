// extracted by mini-css-extract-plugin
export var radioFieldset = "RadioFieldset-module--radioFieldset--31EdO";
export var title = "RadioFieldset-module--title--7w9+l";
export var subtitle = "RadioFieldset-module--subtitle--KtKLk";
export var description = "RadioFieldset-module--description--t-BIi";
export var icon = "RadioFieldset-module--icon--9RKfg";
export var healthProductSelector = "RadioFieldset-module--healthProductSelector--pn5BK";
export var smallPrintPages = "RadioFieldset-module--smallPrintPages--CZuw-";
export var correctAnswer = "RadioFieldset-module--correctAnswer--cQV2y";
export var incorrectAnswer = "RadioFieldset-module--incorrectAnswer--qBSug";
export var petSpeciesSelector = "RadioFieldset-module--petSpeciesSelector--4HYuL";
export var image = "RadioFieldset-module--image--khmnY";